/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React from 'react';
import { StaticQuery, graphql } from "gatsby"

// Libraries
import _ from 'lodash'

// Third Party
import { Helmet } from "react-helmet"
import parse from 'html-react-parser'

import favicon from '../img/logo.png'

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultKeywords: keywords
      }
    }
    file(relativePath: {eq: "logo.png"}) {
      childImageSharp {
        resize(width: 243, quality: 90) {
          src
        }
      }
    }
  }
`;

function SEO({ title, description, image, pathname, article, keywords, yoast }) {
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultKeywords,
            defaultTitle,
            defaultDescription,
            siteUrl,
          },
        },
        file: featuredImage
      }) => {
        if(yoast) {
          const yoastTitleObj = _.find(yoast.meta, ({ property }) => property === 'og:title')
          const yoastDescriptionObj = _.find(yoast.meta, ({ name }) => name === 'description')

          const yoastTitle = _.size(yoastTitleObj) > 0 && yoastTitleObj.content ? yoastTitleObj.content : defaultTitle
          const yoastDescription = _.size(yoastDescriptionObj) > 0 && yoastDescriptionObj.content ? yoastDescriptionObj.content : defaultDescription

          title = title || yoastTitle
          description = description || yoastDescription
        }

        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${siteUrl}${featuredImage.childImageSharp.resize.src}`,
          url: `${siteUrl}${pathname || "/"}`,
          keywords: keywords || defaultKeywords
        }

        return (
          <>
            <Helmet title={parse(seo.title)}>
              <html lang="nl" />
              <link rel="icon" href={favicon} />
              <meta name="description" content={seo.description} />
              <meta name="keywords" content={seo.keywords} />
              <meta name="image" content={seo.image} />
              {seo.url && <meta property="og:url" content={seo.url} />}
              {(article ? true : null) && (
                <meta property="og:type" content="article" />
              )}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.image && <meta property="og:image" content={seo.image} />}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && (
                <meta name="twitter:description" content={seo.description} />
              )}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
              <script 
                defer 
                type="text/javascript"
              >
                {`
                  var _scId="73d850ac-939d-4fdc-9713-9f65487c5576";var _customCSS = "https://www.mistersales.nu/files/chat.css";var ss=document.createElement("script");ss.src = "https://live.steam.eu.com/client/"; var fs=document.getElementsByTagName("script")[0];fs.parentNode.insertBefore(ss,fs);
                `}
              </script>
              {/* Global site tag (gtag.js) - Google Marketing Platform */}
              <script 
                async 
                src="https://www.googletagmanager.com/gtag/js?id=DC-12179483" 
              />
              <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'DC-12179483');
                `}
              </script>
              <script>
                {`
                  gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to': 'DC-12179483/pagev0/homep0+standard'
                  });
                `}
              </script>
              <noscript>
                {`
                  <img src="https://ad.doubleclick.net/ddm/activity/src=12179483;type=pagev0;cat=homep0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=\${GDPR};gdpr_consent=\${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
                `}
              </noscript>
            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO;
